import React, { useEffect } from "react";
import "../index.css";
import curioExample from "../curio_example.png";
import videoExample from "../video_example.png";
import {Link} from "react-router-dom";
import Footer from "./Footer";
import { createStars } from "../utils/createStars"

const Layout = () => {

    useEffect(() => {
        createStars(".stars-container");
    }, [])

    return (
        <div className="relative min-h-screen flex flex-col items-center justify-center bg-gray-900 overflow-hidden px-1 ">
            <div className="absolute text-lg font-semibold text-pink-400 right-8 lg:top-16 top-6 lg:right-36 ">
                <a className="mr-4 hover:text-gray-100" href="/docs"> Docs</a>
                <Link className="hover:text-gray-100" to="/contacts">Contacto</Link>
            </div>
            <div className="stars-container"></div>
            <div className="relative z-10 w-full max-w-3xl lg:px-6 px-2 lg:py-10 py-4 bg-gray-800/80 rounded-lg shadow-lg text-center lg:mt-0 -mt-14">
                <div className="lg:max-w-sm place-self-center max-w-60">
                    <img src="https://d3iaqo7tqsvydr.cloudfront.net/SukiAI.png" alt="Suki AI Logo" />
                </div>

                <p className="text-gray-200 lg:mb-8 lg:mt-4 mt-4 mb-4 text-center font-spaceGrotesk">
                    Suki AI es una plataforma de API y Backend as a Service diseñada para potenciar a los creadores, facilitando la generación automática de contenido web mediante inteligencia artificial. Con su enfoque intuitivo y flexible, Suki AI permite personalizar contenido según criterios como tipo, categoría, tema y longitud, adaptándose a las necesidades específicas de cada proyecto.


                </p>

                <div className="flex justify-between">
                    <a
                        href="https://suki.sojoj.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative bg-gray-700 text-white p-2 rounded-lg shadow-md w-[47%] transform transition-transform duration-500 hover:scale-125 text-center"
                    >
                        <span
                            className="absolute inset-0 bg-gradient-to-r from-pink-500 to-purple-500 rounded-lg -z-10"/>
                        <img src={curioExample} alt="Reels"/>
                        <h4 className="font-semibold mt-1">Blog Posts</h4>
                    </a>

                    <a
                        href="https://vt.tiktok.com/ZSjtBAhdt"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative bg-gray-700 text-white p-2 rounded-lg shadow-md w-[47%] transform transition-transform duration-500 hover:scale-125 text-center"
                    >
                        <span
                            className="absolute inset-0 bg-gradient-to-r from-pink-500 to-purple-500 rounded-lg -z-10"/>
                        <img src={videoExample} alt="Reels"/>
                        <h4 className="font-semibold mt-1">Reels</h4>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
