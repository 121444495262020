import './App.css';
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Contacts from "./components/Contacts";
import Docs from "./components/Docs";

function App() {
    return (
        <Router>
            <Routes>
                {/* Ruta principal */}
                <Route path="/" element={<Layout />} />

                {/* Nueva ruta para Contacts */}
                <Route path="/contacts" element={<Contacts />} />

                    {/* Nueva ruta para Docs */}
                <Route path="/docs" element={<Docs />} />
            </Routes>
        </Router>
    );
}

export default App;
