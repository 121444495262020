export const createStars = (containerSelector, totalStaticStars = 75, totalFallStars = 90) => {
    const container = document.querySelector(containerSelector);
    if (!container) return;

    // Crear estrellas estáticas
    for (let i = 0; i < totalStaticStars; i++) {
        const star = document.createElement("div");
        star.className = "star-static";

        const size = 1 + Math.random() * 2;
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;

        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        star.style.animation = `twinkling ${1 + Math.random() * 5}s ease-in-out infinite`;
        container.appendChild(star);
    }

    // Crear estrellas fugaces
    for (let i = 0; i < totalFallStars; i++) {
        const star = document.createElement("div");
        star.className = "star-fall";

        const size = 1 + Math.random() * 4;
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;

        const randomValue = Math.random();

        if (randomValue < 0.3) {
            star.style.left = `${Math.random() * 100}%`;
            star.style.top = `${Math.random() * 100}%`;
        } else {
            star.style.left = `${Math.random() * 100}%`;
            star.style.top = `${Math.random() * 100}px`;
        }

        if (randomValue < 0.974) {
            star.style.animation = `fall ${4 + Math.random() * 3}s linear ${-Math.random() * 10}s infinite`;
        } else {
            star.style.animation = `fall-diagonal ${1 + Math.random() * 3}s linear ${-Math.random() * 3}s infinite`;
        }

        container.appendChild(star);
    }
};
