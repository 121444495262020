import React, { useEffect } from "react";
import "../index.css";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { createStars } from "../utils/createStars"

const  Docs = () => {

    useEffect(() => {
        createStars(".stars-container");
    }, [])

    return (
        <div className="relative min-h-screen flex flex-col items-center justify-center bg-gray-900 overflow-hidden px-1">
            <div className="absolute text-lg font-semibold text-pink-400 right-8 lg:top-16 top-6 lg:right-36">
                <a className="mr-4 hover:text-gray-100" href="/docs"> Docs</a>
                <Link className="hover:text-gray-100" to="/contacts">Contacto</Link>
            </div>
            <div className="stars-container"></div>
            <div className="relative z-10 w-full max-w-xl px-6 py-10 bg-gray-800/80 rounded-lg shadow-lg text-center lg:mt-0 -mt-12">
                <div className=" lg:max-w-72  place-self-center max-w-60 mb-12">
                    <a href="/"> <img src="https://d3iaqo7tqsvydr.cloudfront.net/SukiAI.png" alt="Suki AI Logo"/> </a>

                </div>


                <div className="flex justify-center">
                    <h1 className="text-white font-semibold text-3xl">WORK IN PROGRESS</h1>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Docs;
