import React, { useEffect } from "react";
import "../index.css";
import  whatsappicon from "../assets/whatsapp.png";
import  email from "../assets/email.png";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { createStars } from "../utils/createStars"

const  Contacts = () => {

    useEffect(() => {
        createStars(".stars-container");
    }, [])

    return (
        <div className="relative min-h-screen flex flex-col items-center justify-center bg-gray-900 overflow-hidden px-1 ">
            <div className="absolute text-lg font-semibold text-pink-400 right-8 lg:top-16 top-6 lg:right-36">
                <a className="mr-4 hover:text-gray-100" href="/docs"> Docs</a>
                <Link className="hover:text-gray-100" to="/contacts">Contacto</Link>
            </div>
            <div className="stars-container"></div>
            <div className="relative z-10 w-full max-w-xl lg:px-6 px-2 py-10 bg-gray-800/80 rounded-lg shadow-lg text-center lg:mt-0 -mt-12">
                <div className="lg:max-w-sm place-self-center max-w-60 mb-12">
                    <a href="/"> <img src="https://d3iaqo7tqsvydr.cloudfront.net/SukiAI.png" alt="Suki AI Logo"/> </a>

                </div>


                <div className="flex justify-between">
                    <a
                        href="mailto:Jonhathansojo@gmail.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative bg-gray-700 text-white p-2 rounded-lg shadow-md w-[48.5%] transform transition-transform duration-300 hover:scale-105"
                    >
                        <span
                            className="absolute inset-0 bg-gradient-to-r from-pink-500 to-purple-500 rounded-lg -z-10"/>

                        <h4 className="font-semibold lg:mt-1 text-center">
                            <img
                                src={email}
                                alt="Email"
                                className="lg:w-7 w-8 block lg:inline-block mx-auto lg:mx-0 mb-1 lg:mr-2"
                            />
                            sojopdev1@gmail.com
                        </h4>
                    </a>

                    <a
                        href="https://wa.link/9mnfdg"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative bg-gray-700 text-white p-2 rounded-lg shadow-md w-[48.5%] transform transition-transform duration-300 hover:scale-105"
                    >
                        <span
                            className="absolute inset-0 bg-gradient-to-r from-pink-500 to-purple-500 rounded-lg -z-10"/>

                        <h4 className="font-semibold lg:mt-1 text-center">
                            <img
                                src={whatsappicon}
                                alt="Whatsapp"
                                className="lg:w-7 w-8 block lg:inline-block mx-auto lg:mx-0 mb-1 lg:mr-2"
                            />
                            +57 320 2227190
                        </h4>
                    </a>
                </div>
            </div>
            <Footer/>
        </div>
    );
};



export default Contacts;
